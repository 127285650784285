import { NOTIFICATIONS_MODULE } from '../modules/notifications'
import { MESSENGER_MODULE } from '../modules/messenger'
import { CALENDAR_MODULE } from '../modules/calendar'
import { FORMS_MODULE } from '../modules/forms'
import { FILES_MODULE } from '../modules/files'
import { ROLES_MODULE, USERS_MODULE } from '../modules/users'
import { CONTENT_MODULE } from '../modules/content'
import { PATIENT_CALENDAR_MODULE } from '../modules/patient-calendar-config'
import { VACCINES_MODULE } from '../modules/vaccines'
import { PATIENTS_MODULE } from '../modules/patients'
import { GOALS_MODULE } from '../modules/goals'
import { CENTER_MODULE } from '../modules/centers'
import { EDUCATIONAL_MODULE } from '../modules/educational'
import { APPOINTMENTS_MODULE } from '../modules/appointments'
import { QUESTIONNAIRES_MODULE } from 'modules/questionnaires/container'
import { FECAL_MODULE } from '../modules/fecal'
import { URINATION_MODULE } from '../modules/urination'

// const apiURL = process.env.REACT_APP_BACKEND_URL
const apiURL = 'https://adminstaging.higeawithyou.com'

export const moduleURLs = {
  [NOTIFICATIONS_MODULE]: `${apiURL}/notifications`,
  [MESSENGER_MODULE]: `${apiURL}/messenger`,
  [CALENDAR_MODULE]: `${apiURL}/calendar`,
  [FORMS_MODULE]: `${apiURL}/forms`,
  [FILES_MODULE]: `${apiURL}/files`,
  [USERS_MODULE]: `${apiURL}/users`,
  [ROLES_MODULE]: `${apiURL}/users/roles`,
  [CONTENT_MODULE]: `${apiURL}/articles`,
  [PATIENT_CALENDAR_MODULE]: `${apiURL}/patient-calendar-config`,
  [VACCINES_MODULE]: `${apiURL}/vaccines`,
  [PATIENTS_MODULE]: `${apiURL}/patients`,
  [GOALS_MODULE]: `${apiURL}/goals`,
  [FECAL_MODULE]: `${apiURL}/fecal`,
  [URINATION_MODULE]: `${apiURL}/urination`,
  [CENTER_MODULE]: `${apiURL}/centers`,

  [EDUCATIONAL_MODULE]: `${apiURL}/educational`,

  [APPOINTMENTS_MODULE]: `${apiURL}/appointments`,

  [QUESTIONNAIRES_MODULE]: `${apiURL}/questionnaires`,
}
